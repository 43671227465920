.App {
  overflow-x: hidden;
}

* {
  transition: all 0.1s ease;
}

ul {
  list-style-type: none;
}

ul li b {
  color: #f31717 !important;
}

li::marker {
  color: #f31717 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

a {
  text-decoration: none !important;
  color: none !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.display-content {
  padding: 10px;
}

.responsive-content {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

.input-field,
.timer {
  padding: 30px;
  width: 50%;
}

.input-field {
  margin: 10px;
}

.content {
  padding: 2%;
  text-align: center;
}

.input-field-buttons {
  margin-bottom: 5px;
}

.menu-items-name {
  font-family: sans-serif;
  font-size: 16x;
  padding-top: 33px;
  padding-bottom: 34px;
  color: black;
  font-weight: 600;
  cursor: pointer;
}

.menu-items-name:hover {
  font-size: 16px;
  padding-top: 33px;
  padding-bottom: 34px;
  color: rgb(223, 32, 32);
  font-weight: 600;
  cursor: pointer;
}

.menu-name {
  padding: 20px;
}

.selected {
  color: rgb(223, 32, 32);
  border-bottom: 5px solid;
  border-color: red;
}

#template-mo-jassa-hero-carousel .carousel-indicators li {
  margin-top: -50px;
  background-color: #fff;
}

.carousel-indicators button {
  background-color: red;
}

.carousel-control {
  display: none;
}

.carousel-indicators [data-bs-target] {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: red;
}

.carousel-inner {
  padding: 0 0%;
}

.carousel-inner h1 {
  font-weight: 800;
  font-size: 70px;
  line-height: 100px;
  color: #FFFFFF;
  letter-spacing: -2px;
  font-weight: bold !important;
}

.carousel-inner h3,
.carousel-inner p {
  color: #fff;
}

.carousel-indicators {
  display: none !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  visibility: hidden !important;
}

.carousel-inner a {
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;

  color: #FFFFFF;
}

.carousel-inner {
  height: auto;
}

.carousel-inner h1 {
  line-height: 150%;
  letter-spacing: 2px;
}

.copyright-text {
  text-align: center;
  border-top: 2px solid;
  padding-top: 1%;
}

.page-title {
  font-size: clamp(1rem, 4vw, 3rem) !important;
  padding: 15%;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.slider-label {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.card-label {
  margin: 0;
  position: absolute;
  top: 85%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.footer-row {
  justify-content: space-between;
  margin-bottom: 30px;
}

.services-row {
  padding-left: 10%;
  padding-right: 10%;
  width: 100%;
}

.services-column {
  padding-top: 2%;
  padding-bottom: 2%;
}

.services-column:hover {
  padding-top: 2%;
  padding-bottom: 2%;
  animation: movecard 2s ease;
  animation-iteration-count: infinite;
}

@keyframes movecard {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.banner {
  width: 100%;
  object-fit: cover;
}

.small-image {
  width: 20%;
  height: 23%;
  margin-left: 80%;
  margin-top: -23%;
}

.home-card-title {
  text-align: center;
  color: black !important;
  cursor: default;
}

.home-card-title:hover {
  text-align: center;
  color: #f31717 !important;
}

.top-to-btm {
  position: relative;
}

.npicon-style {
  background-color: rgb(98, 200, 240);
  border: 2px solid rgb(0, 0, 0);
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  padding: 10px;
  margin: 10px;
}

.icon-position {
  position: fixed;
  bottom: 100px;
  right: 30px;
  z-index: 20;
}

.icon-style {
  background-color: #ce4747;
  border: 2px solid #ce4747;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all .5s ease-in-out;
}

.icon-style:hover {
  animation: none;
  background: #fff;
  color: #ce4747;
  border: 2px solid #ce4747;
}

@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(10px);
  }

  50% {
    transform: translateY(0px);
  }

  75% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.service-table {
  justify-content: center;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td,
table th {
  padding: 2px 0px;
}

.social-link {
  color: grey;
}

.social-link:hover {
  color: white;
}

.top-nav-link {
  color: black;
}

.top-nav-link:hover {
  color: black;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  text-align: center;
  height: 100%;
  opacity: 1;
}

.banner-overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  font-size: clamp(0.5rem, 1rem, 1.5rem);
  text-align: center;
  height: 100%;
  opacity: 1;
}

.banner-image {
  aspect-ratio: 1.5;
  height: 100%;
}

@media (min-width : 850px) {
  .banner-image {
    width: 100%;
    height: 750px;
  }

  .header-banner {
    width: 100%;
    height: 100%;
  }
}

@media (max-width : 850px) {
  .banner-image {
    width: 100%;
    height: 550px;
  }

  .header-banner {
    width: 100%;
    height: 100%;
  }
}

@media (max-width : 650px) {
  .banner-image {
    width: 100%;
    height: 400px;
  }

  .header-banner {
    width: 100%;
    height: 200px;
  }
}

@media (max-width:650px) {
  .banner-heading {
    animation: none !important;
    font-size: clamp(2.5rem, 5vw, 5rem) !important;
  }

  .banner-subheading {
    color: red !important;
    font-size: clamp(1rem, 2vw, 3rem);
    font-weight: bold;
    animation: none !important;
  }

  .banner-description {
    font-size: clamp(0.8rem, 1.5vw, 1.5rem);
    font-weight: 500;
    animation: none !important;
  }

  .btn-banner {
    background-color: red !important;
    color: white !important;
    font-size: clamp(1rem, 1vw, 1.5rem) !important;
    animation: none !important;
    font-weight: 500 !important;
    border: 1px solid red !important;
  }

  .btn-banner:hover {
    background-color: rgb(226, 60, 60) !important;
    border: 1px solid rgb(226, 60, 60) !important;
    color: white !important;
    font-size: clamp(1rem, 1vw, 1.5rem) !important;
  }
}

.btn-download {
  background-color: red !important;
  color: white !important;
  font-size: clamp(1rem, 1vw, 1.5rem) !important;
  animation: none !important;
  font-weight: 500 !important;
  border: 1px solid red !important;
}

.btn-download:hover {
  background-color: rgb(226, 60, 60) !important;
  border: 1px solid rgb(226, 60, 60) !important;
  color: white !important;
  font-size: clamp(1rem, 1vw, 1.5rem) !important;
}

.banner-heading {
  animation: fadeIn 3s ease;
  animation-iteration-count: 1;
  font-size: clamp(2.5rem, 5vw, 5rem) !important;
}

.banner-subheading {
  color: red !important;
  font-size: clamp(1rem, 2vw, 3rem);
  font-weight: bold;
  animation: fadeIn 3s ease;
  animation-iteration-count: 1;
}

.banner-description {
  font-size: clamp(0.8rem, 1.5vw, 1.5rem);
  font-weight: 500;
  animation: fadeIn 3s ease;
  animation-iteration-count: 1;
}

.about-values,
.about-mission,
.about-vision {
  padding: 5% !important;
}

.about-values p,
.about-mission p,
.about-vision p {
  font-size: clamp(0.9rem, 1vw, 1.2rem) !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0%;
    margin-left: -100%;
  }

  100% {
    opacity: 100%;
    margin-right: 0%;
  }
}

.btn-banner {
  background-color: red !important;
  color: white !important;
  font-size: clamp(1rem, 1vw, 1.5rem) !important;
  animation: fadeIn 3s ease;
  animation-iteration-count: 1;
  font-weight: 500 !important;
  border: 1px solid red !important;
}

.btn-banner:hover {
  background-color: rgb(226, 60, 60) !important;
  border: 1px solid rgb(226, 60, 60) !important;
  color: white !important;
  font-size: clamp(1rem, 1vw, 1.5rem) !important;
}

.btn-task {
  background-color: red !important;
  color: white !important;
  font-size: inherit !important;
}

.btn-task:hover {
  background-color: rgb(226, 60, 60) !important;
  color: white !important;
  font-size: inherit !important;
}

.top-banner {
  justify-content: end !important;
  font-family: 'Times New Roman', Times, serif;
  animation: fadeIn 2s;
}

.top-banner-content {
  display: flex;
  margin-right: 10px;
  margin-bottom: -20px;
}

.top-banner-content p {
  font-weight: 600;
}

.banner-logo {
  color: red;
  cursor: pointer;
}

.top-banner-content span {
  text-decoration: underline;
  cursor: pointer;
}

.text-content {
  text-align: start;
  padding-left: 25%;
  padding-right: 25%;
  font-style: italic;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
}

.text-content span {
  text-decoration: underline;
  text-decoration-color: red;
  color: red;
}

.contact-form {
  padding: 5% 5% 5% 5%;
}

.about-content {
  padding: 5%;
  font-family: serif;
  font-size: 1.5rem;
}

.team {
  text-align: center;
  text-decoration: underline;
  text-decoration-color: red;
  font-size: 3rem;
  margin-top: 5% !important;
}

.footer-text-decorate {
  padding: 5%;
}

.error-text {
  color: red;
  text-shadow: 0.9rem 0.8rem 0 #eaeaea;
  font-size: 10rem;
}

.blog-title {
  text-align: start;
  color: black !important;
}

.blog-title:hover {
  color: red !important;
}

.blog-content {
  font-family: sans-serif;
  text-align: start;
}

.blog-content img {
  width: 100%;
  height: 100%;
}

.blog-content a {
  color: red !important;
  text-decoration: underline !important;
  text-decoration-color: red;
}

.featured-image {
  position: relative;
  margin: auto;
  overflow: hidden;
}

.featured-image img {
  max-width: 100%;
  transition: all 0.3s;
  display: block;
  width: 100%;
  height: auto;
  transform: scale(1);
}

.featured-image:hover img {
  transform: scale(1.1);
  opacity: 0.8;
}

.blogs,
.specific-blog {
  padding-left: 15%;
  padding-right: 15%;
  margin-bottom: 5%;
}

.blog {
  color: black !important;
  box-shadow: 2px 2px 10px 5px lightgrey;
  margin-top: 5%;
}

.specific-blog-title {
  text-transform: uppercase;
  font-family: monospace;
}

.blog-text {
  padding: 1.5% 5% 1.5% 5%;
}

.blog-text img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.inputbox:focus {
  border-color: rgb(221, 116, 116) !important;
  box-shadow: 0 0 0 0.15rem rgb(221, 116, 116) !important;
}

.keywords {
  display: flex;
  padding: 1% 5% 1% 5%;
}

.blog-keywords {
  padding: 1%;
  background-color: red;
  color: white;
  font-size: 15px;
  margin-right: 2%;
  border-radius: 3px;
  font-weight: 700;
}

.blog-keywords:hover {
  transition: all .5s ease-in-out;
  background-color: rgb(6, 3, 29);
  color: white !important;
}

.custom-spinner {
  margin-left: 50%;
  margin-right: 50%;
  margin-top: 10%;
}

.blog-comments {
  background: #d3d3d385;
  padding: 10px;
  opacity: 0.8;
  border-radius: 5px;
  font-size: 1rem;
}

.comment {
  background-color: #d3d3d34d;
  padding: 0% 5% 2% 5% !important;
  opacity: 0.85;
  font-size: 1.2rem;
  width: 82.5% !important;
  border-radius: 25px;
}

.reply-comment {
  padding: 0% 5% 2% 5% !important;
  opacity: 0.85;
  font-size: 1.2rem;
  width: 82.5% !important;
  border-radius: 25px;
  border: 1px solid lightgrey;
}

.comment-reply {
  color: #ff0000d6;
  text-align: start;
}

.btn-reply {
  color: red !important;
  border: 2px solid transparent !important;
  padding: 0% !important;
}

.categories {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-bottom: 0rem !important;
  padding-left: 4.5% !important;
}

.category {
  float: left;
}

.category span {
  display: block;
  color: white;
  text-align: center;
  padding: 10px;
  background-color: rgb(255, 50, 50);
  margin: 5px;
  border-radius: 5px;
  font-weight: 500;
}

.category span:hover {
  background-color: red;
}

.collapsible {
  background-color: lightgrey;
  color: black;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  margin-top: 15px;
}

.active,
.collapsible:hover {
  background-color: #BBB;
}

.content {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #f1f1f1;
}

.job-row {
  margin-top: 5% !important;
  padding: 3%;
  box-shadow: 2px 2px 10px 5px lightgrey;
  border-radius: 25px;
  cursor: pointer;
  text-align: center;
}

.btn-apply {
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(0%, 100%);
}

.career-page-title {
  text-align: center;
}

.center {
  margin: 0;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.requirements {
  display: flex;
  justify-content: space-evenly;
}

.top-navbar {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: 'fixed'
}

.top-navbar-items {
  float: left;
  cursor: pointer;
}

.education-row {
  margin-top: 1% !important;
  justify-content: space-around;
}

.education-modules {
  display: flex;
  padding: 2%;
  box-shadow: 2px 2px 10px 5px lightgrey;
  border-radius: 15px;
  color: red;
  font-weight: 500;
  font-size: 1.5rem;
  align-items: center;
  cursor: pointer;
  text-align: center;
  justify-content: center;
}

.download-syllabus {
  font-size: 0.85rem;
  color: black;
}

.hover-overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  background: rgba(0, 0, 0, 0.7);
  /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  height: 100%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.card:hover .hover-overlay {
  opacity: 1;
}

.styles_rsbcText__16wA0 {
  font-weight: 500 !important;
}

.team-info {
  display: flex;
  justify-content: space-between;
}

.btn-payment {
  background: #ff0000d9 !important;
  width: 100% !important;
  color: white !important;
  font-weight: 500 !important;
  border: 0.5px solid #ff0000d9 !important;
}

.portfolio-slider {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
}

.moving-text:hover {
  animation-play-state: paused;
}

/* The animation */
@keyframes marquee {
  0% {
    transform: translateX(25%);
  }

  50% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(25%);
  }
}

/* media query to enable animation for only those who want it */
@media (prefers-reduced-motion: no-preference) {
  .moving-text {
    animation: marquee 15s linear infinite;
  }
}

.page-link {
  cursor: pointer;
}

.videoplayer {
  height: 70vh;
}

.pricing-row {
  padding: 2% 5%;
}

.column-header {
  background: #52E89E !important;
  color: black;
  box-shadow: 1px 0px 5px 0px black;
  font-size: 1rem !important;
}

.first-column {
  background: #47887E;
  color: white;
  font-weight: 600 !important;
  width: 40% !important;
  border: 0.1rem solid white;
}

.second-column {
  background-color: #f55039;
  color: white;
  font-weight: 500 !important;
  border: 0.1rem solid white;
}

.third-column {
  background-color: #46A6F7;
  color: white;
  font-weight: 500 !important;
  border: 0.1rem solid white;
}

.fourth-column {
  background-color: #F59B24;
  color: white;
  font-weight: 500 !important;
  border: 0.1rem solid white;
}

.btn-purchase {
  background-color: grey !important;
  font-size: 1rem !important;
}

.btn-purchase:hover {
  background-color: rgb(158, 158, 158) !important;
  font-size: 1rem !important;
}

.packages {
  text-align: center;
  text-decoration: underline;
  text-decoration-color: red;
  font-size: 2rem;
  margin-bottom: 2%;
}

.socials {
  font-size: clamp(1.5rem, 2vw, 3rem);
  font-weight: 600;
}

.swipe-text {
  color: 'black' !important;
  z-index: 100 !important;
  font-size: clamp(0.9rem, 1vw, 2rem) !important;
  font-weight: 600;
}

.client-slider {
  height: 250px;
  position: relative;
  width: 100%;

  display: grid;
  place-items: center;
  overflow: hidden;
}

.client-slider::before,
.client-slider::after {
  position: absolute;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 40%);
  content: '';
  height: 100%;
  width: 25%;
  z-index: 2;
  pointer-events: none;
}

.client-slider::before {
  left: 0;
  top: 0;
}

.client-slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

/*  IMPORTANT CODE BELOW */

.slide-track2 {
  width: calc(300px * 21);
  display: flex;
  animation: scroll2 21s linear infinite;
  justify-content: space-between;
}

.client-slide {
  width: 200px;
  height: 250px;
  display: grid;
  place-items: center;
  transition: 0.5s;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}

.client-slide:hover {
  transform: scale(1.1)
}

@keyframes scroll2 {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(calc(-300px * 7));
  }
}

@media screen and (max-width: 768px) {
  .slide-track2 {
    width: calc(150px * 21);
  }

  .client-slider::before,
  .client-slider::after {
    position: absolute;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 0%) !important;
    content: '';
    height: 100%;
    width: 25%;
    z-index: 2;
    pointer-events: none;
  }

  @keyframes scroll2 {
    0% {
      transform: translateX(0px);
    }

    100% {
      transform: translateX(calc(-300px * 7));
    }
  }
}

.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 2px 2px 10px 5px lightgrey;
}

.dropdown1 {
  margin-top: auto;
  margin-bottom: auto;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 120px;
  box-shadow: 2px 8px 16px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 1%;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content div {
  color: black;
  padding: 5px 10px;
  text-decoration: none;
}

.dropdown-content a:hover {
  background-color: #f1f1f1
}

.dropdown1:hover .dropdown-content {
  display: block;
}

@media (max-width:1280px) {
  .MuiDrawer-paperAnchorLeft {
    width: 20%;
  }

  .dropdown-content {
    width: 80%;
  }
}

@media (max-width:1150px) {
  .MuiDrawer-paperAnchorLeft {
    width: 25%;
  }

  .dropdown-content {
    width: 80%;
  }
}

@media (max-width:950px) {
  .MuiDrawer-paperAnchorLeft {
    width: 30%;
  }

  .dropdown-content {
    width: 80%;
  }
}

@media (max-width:800px) {
  .MuiDrawer-paperAnchorLeft {
    width: 35%;
  }

  .dropdown-content {
    width: 80%;
  }
}

@media (max-width:680px) {
  .MuiDrawer-paperAnchorLeft {
    width: 40%;
  }

  .dropdown-content {
    width: 80%;
  }
}

@media (max-width:600px) {
  .MuiDrawer-paperAnchorLeft {
    width: 45%;
  }

  .dropdown-content {
    width: 80%;
  }
}

@media (max-width:480px) {
  .MuiDrawer-paperAnchorLeft {
    width: 50%;
  }

  .dropdown-content {
    width: 80%;
  }
}

@media (max-width:390px) {
  .MuiDrawer-paperAnchorLeft {
    width: 60%;
  }

  .dropdown-content {
    width: 80%;
  }
}

@media (max-width:300px) {
  .MuiDrawer-paperAnchorLeft {
    width: 70%;
  }

  .dropdown-content {
    width: 80%;
  }
}

.service-icons {
  margin-left: auto;
  margin-right: auto;
}

.service-names {
  text-align: center !important;
  padding: 20px !important;
}