body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(255, 255, 255);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(172, 172, 172);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(117, 117, 117);
}

:root {
  scroll-behavior: smooth;
}

::-moz-selection {
  background: rgb(230, 41, 41);
  color: white;
  font-weight: 400;
}

::-webkit-selection {
  background: rgb(230, 41, 41);
  color: #fff;
  font-weight: 400;
}

::selection {
  background: rgb(230, 41, 41);
  color: #fff;
  font-weight: 400;
}